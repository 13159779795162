/* widget */
 .service-widget {
     padding: 50px;
     background-color: rgb(255, 255, 255);
     box-shadow: 0px 8px 16px 0px rgba(200, 183, 255, 0.2);
     border-top: 6px solid #0E6B96;
     padding-top: 41px;
}
 .service-widget .widget-title {
     font-size: 40px;
     margin-bottom: 10px;
}
 .doctor-detials-lan ul li {
     display: inline-block;
}
 .doctor-detials-lan ul li a {
     height: 60px;
     width: 60px;
     display: block;
     text-align: center;
     line-height: 60px;
     background: #eae4ff;
     font-size: 14px;
     color: #223645;
     text-transform: uppercase;
     font-weight: 500;
     border-radius: 50%;
     margin-right: 19px;
}
 .doctor-detials-lan ul li a:hover {
     background-color: rgb(143, 181, 105);
     box-shadow: 0px 8px 16px 0px rgba(143, 181, 105, 0.2);
     color: #fff;
}